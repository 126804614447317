export class Slider {
  constructor ({ els, className, interval }) {
    this.els = els;
    this.currentActiveIndex = 0;
    this.className = className;
    this.interval = interval;
  }

  unActiveAll () {
    return this.els.map(item => item.classList.remove(this.className));
  }

  updateCurrentIndex (index) {
    this.currentActiveIndex = index;
  }

  activateByIndex (index) {
    this.els[index].classList.add(this.className);
  }

  incIndex () {
    this.currentActiveIndex = this.currentActiveIndex === (this.els.length - 1) ? 0 : this.currentActiveIndex + 1;
  }

  start () {
    this.unActiveAll();
    this.activateByIndex(this.currentActiveIndex);

    this.timer = setInterval(() => {
      this.unActiveAll();
      this.incIndex();
      this.activateByIndex(this.currentActiveIndex);
    }, this.interval);
  }

  stop () {
    clearInterval(this.timer);
    this.currentActiveIndex = 0;
  }
}
