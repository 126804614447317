import 'babel-polyfill';
import Parallax from 'parallax-js';
import { Slider } from './Slider';
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';
import { sendForm } from './email';
import IMask from 'imask';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const API = 'https://api.logotoshka.com';

const maskOptions = {
  mask: '+{380} 00-000-00-00'
};

window.onload = () => {
  const scene = document.querySelector('.js-clouds');

  // eslint-disable-next-line no-unused-vars
  const parallaxInstance = new Parallax(scene);

  const bubblesEls = [...document.querySelectorAll('.js-bubble')];
  const animalEls = [...document.querySelectorAll('.js-animals-box__item')];

  const bubble = new Slider({
    els: bubblesEls,
    className: 'active',
    interval: 3000
  });
  const animals = new Slider({
    els: animalEls,
    className: 'active',
    interval: 3000
  });

  const animalsBoxRef = document.querySelector('.js-animals-box');

  if (animalsBoxRef) {
    animalsBoxRef.addEventListener('click', (event) => {
      const animalRef = event.target.closest('.js-animals-box__item');

      if (!animalRef) return;

      if (!animalsBoxRef.contains(animalRef)) return;

      const index = animalEls.findIndex(el => el === animalRef);

      bubble.unActiveAll();
      animals.unActiveAll();

      animals.updateCurrentIndex(index);
      bubble.updateCurrentIndex(index);

      bubble.activateByIndex(index);
      animals.activateByIndex(index);
    });

    animalsBoxRef.addEventListener('mouseenter', () => {
      bubble.stop();
      animals.stop();
    });

    animalsBoxRef.addEventListener('mouseleave', () => {
      bubble.start();
      animals.start();
    });
  }

  bubble.start();
  animals.start();

  // SLIDER
  // eslint-disable-next-line no-unused-vars
  const slider = tns({
    container: '.testimonials-slider-js',
    items: 1,
    slideBy: 'page',
    mouseDrag: true,
    controls: false,
    navPosition: 'bottom',
    loop: false,
    responsive: {
      654: {
        items: 2
      }
    }
  });

  // QUESTIONS
  const getHeight = (elem) => {
    elem.style.display = 'block';
    const height = elem.scrollHeight + 'px';
    elem.style.display = '';
    return height;
  };

  const show = (elem) => {
    const contentHeight = getHeight(elem);

    elem.classList.add('show');
    elem.style.height = contentHeight;

    const t = setTimeout(function () {
      elem.style.height = '';

      clearTimeout(t);
    }, 350);
  };

  const hide = (elem) => {
    elem.style.height = elem.scrollHeight + 'px';

    const t = setTimeout(function () {
      elem.style.height = '0';

      clearTimeout(t);
    }, 1);

    const t2 = setTimeout(function () {
      elem.classList.remove('show');

      clearTimeout(t2);
    }, 350);
  };

  const questionsBoxRef = document.querySelector('.questions-js');

  questionsBoxRef.addEventListener('click', (event) => {
    const triggerRef = event.target.closest('.questions__item-js');

    if (!triggerRef) return;

    if (!questionsBoxRef.contains(triggerRef)) return;

    const questionContentRef = triggerRef.querySelector('.questions__content-js');

    if (!triggerRef.classList.contains('active')) {
      triggerRef.classList.add('active');
      show(questionContentRef);
    } else {
      triggerRef.classList.remove('active');
      hide(questionContentRef);
    }
  });

  document.body.classList.remove('js-opaque');

  // CONTACT FORM
  const messagePopupRef = document.getElementById('message-popup');
  const messagePopupCloseRef = document.querySelector('.contact-popup-alert__close-js');
  const messagePopupOverlayRef = document.querySelector('.contact-popup-overlay-js');
  const messagePopupHeaderRef = document.querySelector('.contact-popup-alert__content-header-js');
  const messagePopupBodyRef = document.querySelector('.contact-popup-alert__content-body-js');
  const messagePopupFooterRef = document.querySelector('.contact-popup-alert__content-footer-js');

  const topFormRef = document.querySelector('.js-call-form-top');
  const bottomFormRef = document.querySelector('.js-call-form-bottom');

  const topFormPhoneRef = topFormRef.elements.phone;
  const bottomFormPhoneRef = bottomFormRef.elements.phone;

  IMask(topFormPhoneRef, maskOptions).value = '+380';
  IMask(bottomFormPhoneRef, maskOptions).value = '+380';

  const updatePopupContent = ({ status }) => {
    if (status === 'success') {
      messagePopupHeaderRef.innerHTML = '<p class="section-title text--uppercase section-title--secondary">Дякуємо, Вашу заявку прийнято!</p>';
      messagePopupBodyRef.innerHTML = '<p class="text-basic">Ми опрацюємо вашу заявку у найближчий <br>час, зауважте що робота над заявкою<br> ведеться <span class="text-basic text-basic--secondary">виключно у робочий час</span></p>';
      messagePopupFooterRef.innerHTML = '<p class="section-title section-title--secondary section-title--md">Понеділок-п’ятниця <br>з 9:00 до 19:00</p>';
    } else {
      messagePopupHeaderRef.innerHTML = '<p class="section-title text--uppercase section-title--secondary">Щось пішло не за планом :(</p>';
      messagePopupBodyRef.innerHTML = '<p class="text-basic">Спробуйте ще раз, можливо ви помилилися <br>з форматом телефонного номеру<br>+380 (ХХ) ХХХ ХХ ХХ</p>';
      messagePopupFooterRef.innerHTML = '<p class="section-title section-title--secondary section-title--md">Або зателефонуйте нам: <br><a class="text-decoration-none section-title section-title--secondary section-title--md" href="tel:+380733242210">+380 73 324 22 10</a></p>';
    }
  };

  const showPopup = ({ className, status, elRef }) => {
    elRef.dataset.status = status;
    elRef.classList.add('open');
    elRef.classList.add(className);
  };

  const hidePopup = ({ elRef }) => {
    elRef.dataset.status = '';
    elRef.classList.remove('open');
    elRef.classList.remove('contact-popup--success');
    elRef.classList.remove('contact-popup--error');
  };

  messagePopupCloseRef.addEventListener('click', () => {
    hidePopup({ elRef: messagePopupRef });
  });

  messagePopupOverlayRef.addEventListener('click', () => {
    hidePopup({ elRef: messagePopupRef });
  });

  const formHandler = async (e) => {
    e.preventDefault();

    const errors = [];
    const elements = e.target.elements;

    const [nameRef, phoneRef, submitBtnRef] = elements;

    const nameValue = nameRef.value;
    const phoneValue = phoneRef.value;

    if (nameValue.length < 3) {
      errors.push({ name: 'Довжина поля має бути більша за 3 символи.' });
    }

    if (phoneValue.length < 17) {
      errors.push({ phone: 'Ви ввели не коректний номер.' });
    }

    if (errors.length) {
      return false;
    }

    submitBtnRef.disabled = true;
    submitBtnRef.innerHTML = 'Відправка...';

    const response = await sendForm(`${API}/contact/email/send`, {
      name: nameValue,
      phone: phoneValue
    });

    submitBtnRef.disabled = false;
    submitBtnRef.innerHTML = 'Замовити Дзвінок';

    if (response.error || !response) {
      updatePopupContent({ status: 'error' });

      showPopup({ className: 'contact-popup--error', status: 'error', elRef: messagePopupRef });
    } else {
      updatePopupContent({ status: 'success' });

      showPopup({ className: 'contact-popup--success', status: 'success', elRef: messagePopupRef });

      // eslint-disable-next-line no-undef
      gtag_report_conversion();

      // eslint-disable-next-line no-undef
      gtag('event', 'send', { event_category: 'form', event_label: 'success', value: '' });
    }
  };

  topFormRef.addEventListener('submit', formHandler);

  bottomFormRef.addEventListener('submit', formHandler);

  document.querySelector('.scroll-to-js').addEventListener('click', (e) => {
    e.preventDefault();

    animalsBoxRef.scrollIntoView({ behavior: 'smooth' });
  });

  // End: CONTACT FORM
};
