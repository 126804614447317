export const sendForm = async (url, body) => {
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  try {
    // eslint-disable-next-line no-undef
    const fetchResponse = await fetch(url, settings);
    return await fetchResponse.json();
  } catch (e) {
    return {
      error: true,
      message: e
    };
  }
};
